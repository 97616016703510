import React from 'react';
import Layout from 'src/components/layout/layout';
import ContentfulRichText from 'src/components/misc/contentful-rich-text';
import SEO from 'src/components/misc/seo';
import Container from 'src/components/widget/container';
import { StaticContextType } from 'typings/custom';
import { ILegalTexts } from 'typings/generated/contentful';

type Props = {
  pageContext: {
    reactContext: StaticContextType;
    legalText: ILegalTexts;
  };
};

const LegalPage = ({ pageContext }: Props) => {
  const { legalText, reactContext } = pageContext;

  return (
    <Layout context={reactContext}>
      <SEO description="" title={legalText.fields.name} />
      <div className="flex justify-center pb-16">
        <Container className="space-y-4">
          <ContentfulRichText document={legalText.fields.bodyText} />
        </Container>
      </div>
    </Layout>
  );
};

export default LegalPage;
